/*
 * this file shall contain all panelClass classes used to style the cdk-overlay (e.g. for mat-dialog or mat-menu)
 */
@use 'base/colors' as *;

.cdk-overlay-container {
  .cdk-overlay-pane.transportation-info-tooltip {
    .cos-popover {
      padding: 3px 5px;
      border: 1px solid $cos-red;

      .net-price {
        margin-right: 5px;
      }
    }
  }

  .enzo-modal-message {
    &.cdk-overlay-pane {
      max-height: 95%;
    }

    .cdk-dialog-container {
      flex: 1; // make it use all the width (given as parameter "width" to the dialog config)
      outline: none; // remove the default focus outline
    }
  }
}

.enable-overlay-vertical-scrolling {
  overflow: scroll;
}

.no-padding-dialog,
.no-padding-dialog .mat-dialog-container {
  padding: 0 !important;
}

.bidding-confirmation-dialog-container {
  max-width: 450px !important; // override "style="
  width: 100%;
}

.underline-links a {
  text-decoration: underline;
}

.loyalty-info {
  ng-component .cos-popover {
    border-radius: 5px;
    padding: var(--enzo-spacer-6);
    box-shadow:
      0 4px 20px 0 rgba($cos-black, 0.1),
      0 2px 4px 0 rgba($cos-black, 0.2);
  }
}

// panelClass 'toolbar-mat-menu': removes padding from the mat-menu-content (3rd class added for more specificity)
.mat-menu-panel.toolbar-mat-menu .mat-menu-content {
  padding-top: 0;
  padding-bottom: 0;
}

.custom-mobile-app-banner mat-dialog-container {
  padding: 0;
  overflow: hidden;
}

mat-tooltip-component {
  .equipment-tooltip {
    margin-top: -15px;
  }

  .bird-eye-tooltip {
    margin-top: 5px;
  }

  .multiline-tooltip {
    white-space: pre-line;

    & > * {
      text-align: left;
    }
  }
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.adv-order-section.cdk-drag-preview {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px;
  border-radius: 10px;
  background-color: $cos-grey-ultralight;
  filter: drop-shadow(0 1px 2px rgba($cos-blackish, 0.25));
  cursor: grabbing;
  pointer-events: auto !important;

  .section-text {
    display: grid;
    gap: var(--enzo-spacer-1);
  }
}

.internal-split-modal {
  .mat-tab-body-wrapper {
    height: 100%;
  }

  .mat-tab-body-content {
    padding: 0 10px 10px;
    overflow-x: hidden;
  }
}
